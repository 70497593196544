<!-- 应用装饰 -->
<template>
  <div>应用装饰</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  methods: {}
}

</script>
<style lang='scss' scoped>
</style>